.container {
    background-color: #f8f9fa; 
    padding: 20px;
    border-radius: 10px;
    opacity: 0; 
    animation: fadeIn 3s ease-in-out forwards; 
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.header-background {
    background-image: url('../images/services.jpg'); 
    background-size: cover;
    background-position: center; 
    height: 400px; 
    display: flex; 
    align-items: center; 
    padding-left: 20px; 
    color: white; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); 
    width: 99vw; 
    position: relative; 
    left: 50%; 
    right: 50%; 
    margin-left: -50vw; 
    margin-right: -50vw;
    opacity: 0; 
    animation: slideIn 1.5s ease-in-out forwards;
}

@keyframes slideIn {
    from {
        transform: translateX(100vw); 
        opacity: 0;
    }
    to {
        transform: translateX(0); 
        opacity: 1;
    }
}

.header-background h2 {
    margin: 0;
    font-size: 8.0rem; 
}

.row {
    margin-top: 30px; 
}

.card {
    transition: transform 0.2s, background-color 0.2s; 
    background-color: white; 
    opacity: 0; 
    animation: cardFadeIn 2s ease-in-out 1s forwards; 
}

@keyframes cardFadeIn {
    to {
        opacity: 1; 
    }
}

.card:hover {
    transform: scale(1.05); 
    background-color: #007bff; 
    color: white; 
}

.card-title {
    font-weight: bold; 
}

.card:hover .card-title {
    color: white; 
}

.card-text {
    color: black; 
}

.card:hover .card-text {
    color: #e0e0e0; 
}
