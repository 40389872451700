.about-us-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    opacity: 0; 
    animation: fadeIn 3s ease-in-out forwards; 
}
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.header-background-image {
    background-image: url('../images/aboutus.jpg'); 
    background-size: cover;
    background-position: center; 
    height: 400px; 
    display: flex; 
    align-items: center; 
    justify-content: flex-end; 
    padding-right: 20px; 
    color: white; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); 
    width: 99vw;
    position: relative; 
    left: 50%; 
    right: 50%; 
    margin-left: -50vw; 
    margin-right: -55vw;
    opacity: 0; 
    animation: slideIn 1.5s ease-in-out forwards; 
}

@keyframes slideIn {
    from {
        transform: translateX(100vw);
        opacity: 0;
    }
    to {
        transform: translateX(0); 
        opacity: 1;
    }
}

.header-background-image h2 {
    margin: 0;
    font-size: 8.0rem; 
}

.content {
    opacity: 0; 
    animation: contentFadeIn 1.5s ease-in-out 0.5s forwards; 
}

@keyframes contentFadeIn {
    to {
        opacity: 1; 
    }
}

.content h3 {
    margin-top: 30px; 
    line-height: 1.6; 
    font-size: 1.5rem; 
}

.content p {
    margin-bottom: 15px; 
    font-size: 1.2rem; 
}
