.r1{
    background-image: url('../images/Register.jpg');
    padding: 140px;
    padding-right: 40px;
    margin-bottom: 30px;
}
#r1{
    color: white;
    text-align: end;
    font-size: 40px;
}
.l1{
    margin-left: 0%;
}
.form-control{
    border: 3px solid grey;
}
.i1{
    position: absolute;
    width: 14px;
    right: 34px;
    top: 12px;
}
.i2{
    position: absolute;
    width: 14px;
    right: 70px;
    bottom: -215px;
}
.i3{
    position: absolute;
    width: 14px;
    right: 65px;
    bottom: -275px;
}
.i4{
    position: absolute;
    width: 14px;
    right: 70px;
    bottom: -535px;
}

