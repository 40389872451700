body {
    background-color: #f8f9fa; 
}
.privacypolicy-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    opacity: 0; 
    animation: fadeIn 3s ease-in-out forwards; 
}
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
.privacypolicy-background-image {
    background-image: url('../images/privacypolicy.jpg'); 
    background-size: cover;
    background-position: center; 
    height: 400px; 
    display: flex; 
    align-items: center; 
    justify-content: flex-end; 
    padding-right: 20px; 
    color: rgb(255, 255, 255); 
    text-shadow: 1px 1px 2px rgb(254, 251, 251); 
    width: 99vw;
    position: relative; 
    left: 50%; 
    right: 50%; 
    margin-left: -50vw; 
    margin-right: -55vw;
    opacity: 0; 
    animation: slideIn 1.5s ease-in-out forwards; 
}

@keyframes slideIn {
    from {
        transform: translateX(100vw);
        opacity: 0;
    }
    to {
        transform: translateX(0); 
        opacity: 1;
    }
}

.privacypolicy-background-image h2 {
    margin: 0;
    font-size: 8.0rem; 
    color: #f8f9fa;
}

.container {
    background-color: #ffffff; 
    padding: 40px; 
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
    max-width: 1200px; 
    margin: auto; 
}

h1 {
    color: RYB(0, 0, 0); 
    text-align: center; 
}

h2 {
    color: #343a40; 
    margin-top: 20px; 
    margin-bottom: 10px; 
    text-align: left; 
}

p, ul {
    color: #495057; 
    text-align: left; 
    margin-bottom: 15px; 
}

ul {
    list-style-type: disc; 
    margin-left: 20px;
}

strong {
    color: #007bff; 
}


h1, h2, p, ul {
    margin-left: 0; 
    padding-left: 0; 
}


p, li {
    line-height: 1.5; 
}
