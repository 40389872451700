.footer-container {
    background-image: url('../images/Footer.jpg');
    margin-top: 20%;
    height: 350px;
    display: flex; 
    justify-content: space-between; 
    padding: 20px; 
}

.footer-col {
    flex: 1; 
    margin: 0 20px; 
}

.footer-col h3 {
    margin-top: 30px;
    margin-bottom: 25px;
    color: white;
    font-size: 25px;
}

.footer-col a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin-left: 20px;
}

.footer-col p {
    color: white;
}


.footer-address, .footer-phone, .footer-email {
    display: flex; 
    align-items: center; 
    margin-bottom: 25px; 
}

.footer-address img, .footer-phone img, .footer-email img {
    margin-right: 5px; 
}
