@keyframes shift {
  
    0%, 100% {
      transform: translateY(0); 
    }
    50% {
      transform: translateY(-3px); 
    }
  }
  
  .shift-button {
    animation: shift 0.5s ease infinite;
  }
  
  .shift-button:hover {
    animation-play-state: running; 
  }