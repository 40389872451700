.contact-container {
    width: 90%;
    padding-top: 30px;
}

.form-control {
    border: 3px solid grey;
    padding-right: 30px; 
}

.input-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
}

.input-group {
    position: relative; 
}
