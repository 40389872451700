
.social-icon i {
    color: white;
  }
  .icon:hover i {
    color: rgba(197, 21, 21, 0.927);
    background-color: white; 
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .navbar {
    animation: slideDown 1s ease-in-out;
  }

  .navbar .nav-link {
    color: #000;
    font-weight: 500;
    padding-right: 20px;
  }
  
  .navbar .nav-link:hover {
    color: rgba(197, 21, 21, 0.927);
    text-decoration: underline;
  }
  .navbar .nav-link.active {
    color: rgba(197, 21, 21, 0.927);
    text-decoration: underline;
    
   
  }
  .navbar a{
    font-size: large;
    
   
  
  }
  @keyframes slideFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .logo {
    animation: slideFromRight 1s ease-in-out forwards;
    max-width: 100px; 
    max-height: 100px;
    display: inline-block;
  }
  

  .menu {
    animation: slideFromLeft 1s ease-in-out forwards;
  }